const tailored = {
  title: `Something special? Let's start!`,
  subTitle: `Don't waste your time on finding a domain name. Let us do it for you.`,
  price: '850',
  service:
    'We will create a domain name that fit your business idea. We’ll take care of the research process so you can focus on holding your business. (Please tell us if you want a static html website for an additional charge).',
  process: [
    'Tell us about your business idea in a brief.',
    'We will research domain names that fit you and offer three options.',
    'You will choose one of the provided options.',
    'We will create for you a domain name.',
  ],
  result: ['Domain Name'],
};

export default tailored;