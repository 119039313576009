const about = {
  name: 'DOMDEMON',
  domain: 'domdemon.com',
  mailInfo: 'info@domdemon.com',
  mailSales: 'sales@domdemon.com',
  mailTailored: 'tailored@domdemon.com',
  service: 'Domain Names',
  detailsBtn: 'Details',
  detailsBtn2: 'Go back',
  title: 'DEMONSTRATION',
  subTitle: 'A-list of uncompromising domain names',
  disclaimer: 'All logos shown are for illustration purposes only.',
  disclaimer2:
    'The logo shown is for illustration purposes only. Please tell us if you want a static html website for an additional charge.',
  imageInfo: 'Optionally static html website for an additional charge.',
  whatYouGet: 'A domain name.',
  secured: 'Transactions are secured by SSL encryption.',
  about: `We provide bankable domain names for anyone. Our mission is to create a strong track record of successes.`,
};

export default about;
