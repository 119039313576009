import React from 'react';
import { Link } from 'react-router-dom';

const Brand = props => {
  const favoriteExist = !!props.favorites.find(
    (b) => b.name === props.brand.name
  );

  const handleFavorite = e => {
    props.toggleFavorite(props.brand.name, favoriteExist);
  };

  return (
    <div key={props.brand.name} className='col-xs-6 col-sm-6 col-md-4 col-lg-3'>
      <div>
        <div className='card mb-3 box-shadow imageContainer'>
          <img
            style={{ position: 'relative' }}
            className='card-img-top image'
            src={props.brand.logoSrc}
            alt={props.brand.name}
          />
          <p
            style={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              color: '#007f8c',
              userSelect: 'none',
              padding: '0 5px 0 5px',
              borderRadius: '5px',
              backgroundColor: 'rgb(248,249,250, 0.5)',
            }}
          >
            {props.brand.badge && props.brand.badge}
          </p>
          <span
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
          >
            <i
              onClick={handleFavorite}
              style={{
                cursor: 'pointer',
                color: favoriteExist ? props.colors.heart : '#c0c6ce',
              }}
              className={favoriteExist ? 'fa fa-heart' : 'fa fa-heart-o'}
              title={favoriteExist ? 'UnFavorite' : 'Favorite'}
            ></i>
          </span>
          <Link to={'/brand/' + props.brand.name}>
            <div className='imageMiddle'>
              <div className='imageText'>
                <span>
                  {props.imageInfo && props.imageInfo}
                </span>
              </div>
            </div>
          </Link>
          <div className='card-body'>
            <p style={{ textAlign: 'center' }} className='card-text'>
              <Link to={'/brand/' + props.brand.name}>{props.brand.name}</Link>
            </p>

            <div className='d-flex justify-content-between align-items-center'>
              <div className='btn-group'>
                <Link
                  to={'/brand/' + props.brand.name}
                  className='btn btn-sm btn-light'
                >
                  {props.detailsBtn && props.detailsBtn}
                </Link>
              </div>
              <small className='text-muted'>
                {props.brand.price ? '$' + props.brand.price : 'Negotiable'}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;